import React, { useState } from "react"
import Layout from "../components/layout-iframe"
import SEO from "../components/seo"
import Loadable from "@loadable/component"
import "../css/global.css"
const Chat = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Chat/iframe-Chat")
)
const GDPR = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Chat/iframe-GDPR")
)

function IndexPage() {
  const [chatOpen, setChatOpen] = useState(true)
  const [load, setLoad] = useState(true) // defines when the chat starts to connect with the bot
  const [accepted, setAccepted] = useState(false)
  const isSSR = typeof window === "undefined"
  const queryString =
    typeof window !== "undefined" ? window.location.search : ""
  const urlParams = new URLSearchParams(queryString)
  const source = urlParams.has("utm_source") ? urlParams.get("utm_source") : ""
  return (
    <Layout>
      <SEO
        title="Dein Corona Navi"
        description="Deine zentrale Anlaufstelle für alle Themen rund um Corona."
      />
      {chatOpen && !accepted && (
        <GDPR
          open={chatOpen && !accepted}
          onClose={open => setChatOpen(open)}
          onEnter={() => setLoad(true)}
          setAccepted={() => {
            setAccepted(true)
            typeof window !== "undefined" &&
              window.plausible &&
              window.plausible("Open Chat Iframe", {
                props: { Source: source },
              })
          }}
        />
      )}
      {!isSSR && load && (
        <Chat open={chatOpen && accepted} onClose={open => setChatOpen(open)} />
      )}
    </Layout>
  )
}

export default IndexPage
