import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import "../fonts/sanfranciscodisplay-ultralight-webfont.woff"
import "../fonts/sanfranciscodisplay-bold-webfont.woff"
import "../fonts/sanfranciscodisplay-semibold-webfont.woff"
import "../fonts/sanfranciscodisplay-medium-webfont.woff"
import "../fonts/sanfranciscodisplay-thin-webfont.woff"
import "../fonts/sanfranciscodisplay-regular-webfont.woff"

import { StyleReset, ThemeProvider, Div } from "atomize"

const theme = {
  fontFamily: {
    primary:
      '"SF Pro Text",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    secondary:
      '"SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    code: "'Fira Mono','Roboto Mono', monospace",
  },
  grid: {
    containerMaxWidth: {
      xl: "1184px",
    },
  },
  textSize: {
    size: {
      display3: "48px",
    },
    height: {
      display3: "56px",
    },
  },
  colors: {
    brandgray: "#F7F5F4",
  },
}

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyleReset />
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      <Div tag="main" overflow="hidden">
        {children}
      </Div>
      {/* <Footer /> */}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
